import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import { slugify } from "@utils/slugify"
import { useTheme } from "styled-components"

const Sitemap = ({ data }) => {
  const { color } = useTheme()

  return (
    <Layout fullWidth title={`Sitemap`} description={`Klipfolio's Sitemap`}>
      <Container>
        <Heading margin="2rem 0" center>
          Sitemap
        </Heading>

        <Heading as="h2" margin="0 0 1rem">
          Products
        </Heading>
        <Grid columns="1fr 1fr" margin="0 0 2rem" columnsSm="1fr" gap="0.5rem">
          <Anchor link="/pricing">Pricing</Anchor>
        </Grid>

        {/* Klips Content */}
        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/klips" color={color.indigo600} arrow>
            Klips
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" margin="0 0 2rem" columnsSm="1fr" gap="0.5rem">
          <Anchor link="/klips/custom-theme">Custom Theme Add-On</Anchor>
          <Anchor link="/klips/partners">Klips for Client Reporting</Anchor>
          <Anchor link="/business-intelligence-and-analytics-software">
            Best Business Intelligence Software in 2024
          </Anchor>
          <Anchor link="/capterra">Capterra</Anchor>
          <Anchor link="/marketplace-leader-data-visualization-g2-crowd">
            G2 Crowd Reviews and Rankings
          </Anchor>
          <Anchor link="https://www.klipfolio.com/klips/videos">Videos</Anchor>
          <Anchor link="https://www.klipfolio.com/klips/quick-tips">
            Quick Tips
          </Anchor>
          <Anchor link="https://www.klipfolio.com/learn-hub">Learn Hub</Anchor>
          <Anchor link="https://www.klipfolio.com/live-dashboards">
            Live Dashboards
          </Anchor>
        </Grid>

        <Heading as="h4" margin="1rem 0">
          <Anchor color={color.indigo500} link="/klips/integrations">
            Integrations
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allGalleryServices.edges.map(({ node: service }) => {
            if (service.just_docs) return
            return (
              <Anchor
                key={`klips-integration-${service.id}`}
                link={`/integrations/${service.slug}`}
              >
                {service.name}
              </Anchor>
            )
          })}
        </Grid>

        <Heading as="h4" margin="0 0 1rem">
          <Anchor color={color.indigo500} link="/gallery/klips">
            Gallery
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allGalleryKlips.edges.map(({ node: klip }) => (
            <Anchor
              key={`klips-gallery-${klip.id}`}
              link={`/gallery/klips/${klip.slug}`}
            >
              {klip.name}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h4" margin="0 0 1rem">
          <Anchor color={color.indigo500} link="/solutions">
            Solutions
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allKlipsSolutions.edges.map(({ node: solution }) => (
            <Anchor
              key={`klips-solution-${solution.id}`}
              link={`/solutions/${solution.slug}`}
            >
              {solution.name}
            </Anchor>
          ))}
        </Grid>

        {/* PowerMetrics Content */}
        <Heading as="h3" margin="1rem 0">
          <Anchor arrow color={color.indigo600} link="/powermetrics">
            PowerMetrics
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          <Anchor link="/powermetrics/partner-program">Partner Program</Anchor>
          <Anchor link="/powermetrics-for-stripe">
            PowerMetrics for Stripe
          </Anchor>
          <Anchor link="/saas-analytics">Full Funnel SaaS Analytics</Anchor>
          <Anchor link="/dashboards">
            Best Custom Dashboard Software & Reporting Tool
          </Anchor>
          <Anchor link="/dashboard-templates">Dashboard Templates</Anchor>
          <Anchor link="/whats-new">What&apos;s New</Anchor>
          <Anchor link="/request-demo">Request Demo</Anchor>
          {data.allPowermetricsLp.edges.map(({ node: lp }) => (
            <Anchor key={`powermetrics-lp-${lp.id}`} link={`/lp/${lp.slug}`}>
              {lp.seo.title}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h4" margin="0 0 1rem">
          <Anchor link="/powermetrics/integrations" color={color.indigo500}>
            PowerMetrics Integrations
          </Anchor>
        </Heading>
        <Grid
          columns="1fr 1fr"
          columnsMd="1fr 1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          <Anchor link="/powermetrics/integrations/semantic-layer">
            Unlock the value of metrics from your semantic layer for everyone
          </Anchor>
          <Anchor link="/powermetrics/integrations/semantic-layer/dbt">
            dbt™ Semantic Layer: Unlock the value of metrics for everyone
          </Anchor>
          {data.allWarehouses.edges.map(({ node: wh }) => (
            <Anchor
              key={`powermetrics-warehouse-${wh.id}`}
              link={`/powermetrics/integrations/warehouses/${wh.slug}`}
            >
              {wh.seo.title}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h4" margin="0 0 1rem">
          <Anchor link="/compare" color={color.indigo500}>
            Compare With Klipfolio
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsMd="1fr 1fr" gap="0.5rem">
          {data.allAlternate.edges.map(({ node }) => (
            <Anchor
              key={`powermetrics-compare-${node.slug}`}
              link={`/compare/${node.slug}`}
            >
              {node.seo.title}
            </Anchor>
          ))}
        </Grid>

        {/* Klipfolio Content */}
        <Heading as="h2" margin="3rem 0 1rem">
          Klipfolio
        </Heading>
        <Grid
          columns="1fr 1fr"
          columnsMd="1fr 1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          <Anchor link="/clients">Klipfolio Customers</Anchor>
          <Anchor link="/reviews">Reviews</Anchor>
          <Anchor link="/services">Services</Anchor>
          <Anchor link="/migrating-ga4">Migrating to Google Analytics 4</Anchor>
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/about" color={color.indigo500}>
            About
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          <Anchor link="/about/awards">Awards and Badges</Anchor>
          <Anchor link="/about/careers">Careers</Anchor>
          <Anchor link="/about/contact-us">Contact Us</Anchor>
          <Anchor link="/about/story-of-klipfolio">Story of Klipfolio</Anchor>
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor color={color.indigo500} link="/branding-guidelines">
            Branding Guidelines
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          <Anchor link="/branding-guidelines/klipfolio-logo">
            Klipfolio Logo
          </Anchor>
          <Anchor link="/branding-guidelines/klipfolio-badge">
            Klipfolio Badge
          </Anchor>
          <Anchor link="/branding-guidelines/klipfolio-colours">
            Klipfolio Colours
          </Anchor>
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/legal" color={color.indigo500}>
            Legal
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allLegal.edges.map(({ node: legal }) => (
            <Anchor
              key={`klipfolio-legal-${legal.id}`}
              link={`/legal/${legal.slug}`}
            >
              {legal.title}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/partners" color={color.indigo500}>
            Partners
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allPartners.edges.map(({ node: partner }) => (
            <Anchor
              key={`klipfolio-partner-${partner.slug}`}
              link={`/partners/${partner.slug}`}
            >
              {partner.name}
            </Anchor>
          ))}
        </Grid>

        {/* Resources */}
        <Heading as="h2" margin="3rem 0 1rem">
          <Anchor link="/resources" arrow color={color.indigo600}>
            Resources
          </Anchor>
        </Heading>

        <Heading as="h3" margin="0 0 1rem">
          Article Categories
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allArticleCategories.edges.map(({ node: category }) => (
            <Anchor
              key={`resources-article-category-${category.id}`}
              link={`/${category.slug}`}
            >
              {category.name}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="1rem 0 1rem">
          <Anchor color={color.indigo500} link="/resources/articles">
            Articles
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allArticles.edges.map(({ node: article }) => (
            <Anchor
              key={`resources-article-${article.id}`}
              link={`/${article.category.slug}/${article.slug}`}
            >
              {article.header}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/blog" color={color.indigo500}>
            Blog
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allBlog.edges.map(({ node: post }) => (
            <Anchor key={`resources-blog-${post.id}`} link={`/${post.slug}`}>
              {post.title}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          Authors
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allAuthors.edges.map(({ node }) => (
            <Anchor
              key={`resources-author-${node.slug ?? slugify(node.name)}`}
              link={`/author/${node.slug ?? slugify(node.name)}`}
            >
              {node.name}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/resources/webinars" color={color.indigo500}>
            Webinars
          </Anchor>
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allWebinars.edges.map(({ node }) => (
            <Anchor
              key={`resources-webinar-${node.slug}`}
              link={`/resources/webinars/${node.slug}`}
            >
              {node.seo.title}
            </Anchor>
          ))}
        </Grid>

        {/* Examples */}
        <Heading as="h3" margin="0 0 1rem">
          <Anchor color={color.indigo500} link="/resources/dashboard-examples">
            Dashboard Examples
          </Anchor>
        </Heading>
        <Heading as="h4" margin="0 0 1rem">
          Categories
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allDashboardExamplesCategories.edges.map(
            ({ node: category }) => (
              <Anchor
                key={`resources-dashboard-examples-category-${category.id}`}
                href={`/resources/dashboard-examples/${category.slug}`}
              >
                {category.name}
              </Anchor>
            )
          )}
        </Grid>
        <Heading as="h4" margin="0 0 1rem">
          Examples
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allDashboardExamples.edges.map(({ node: example }) => (
            <Anchor
              key={`resources-dashboard-examples-${example.id}`}
              href={`/resources/dashboard-examples/${example.category.slug}/${example.slug}`}
              margin="0 0 .5rem"
            >
              {example.name}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/resources/kpi-examples" color={color.indigo500}>
            KPI Examples
          </Anchor>
        </Heading>
        <Heading as="h4" margin="0 0 1rem">
          Categories
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allKpiExamplesParentCategory.edges.map(({ node: category }) => (
            <Anchor
              key={`resources-kpi-examples-category-${category.id}`}
              href={`/resources/kpi-examples/${category.slug}`}
            >
              {category.name}
            </Anchor>
          ))}
          {data.allKpiExamplesCategories.edges.map(({ node: category }) => (
            <Anchor
              key={`resources-kpi-examples-category-${category.id}`}
              href={`/resources/kpi-examples/${category.slug}`}
            >
              {category.name}
            </Anchor>
          ))}
        </Grid>
        <Heading as="h4" margin="0 0 1rem">
          Examples
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allKpiExamples.edges.map(({ node: example }) => (
            <Anchor
              key={`resources-kpi-examples-${example.id}`}
              href={`/resources/kpi-examples/${example.category.slug}/${example.slug}`}
              margin="0 0 .5rem"
            >
              {example.name}
            </Anchor>
          ))}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          <Anchor link="/metric-stack" color={color.indigo500}>
            Metric Stack Podcast
          </Anchor>
        </Heading>
        <Grid
          columns="repeat(4, 1fr)"
          columnsMd="1fr 1fr"
          columnsSm="1fr"
          gap="0.5rem"
          margin="0 0 2rem"
        >
          {data.allMetricStackEpisode.edges.map(({ node: episode }) => {
            const path = `/metric-stack/episode/${
              episode.episode_number
            }/${slugify(episode.guest_name)}`.toLowerCase()
            return (
              <Anchor key={`metric-stack-${episode.id}`} href={path}>
                {episode.title}
              </Anchor>
            )
          })}
        </Grid>

        <Heading as="h3" margin="0 0 1rem">
          Analytics
        </Heading>
        <Heading as="h4" margin="0 0 1rem">
          Categories
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allAnalyticsCategory.edges.map(({ node: category }) => (
            <Anchor
              key={`analytics-category-${category.id}`}
              href={`/resources/analytics/${category.slug}`}
            >
              {category.seo.title}
            </Anchor>
          ))}
        </Grid>
        <Heading as="h4" margin="0 0 1rem">
          Examples
        </Heading>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="0.5rem" margin="0 0 2rem">
          {data.allAnalytics.edges.map(({ node: page }) => (
            <Anchor
              key={`analytics-${page.id}`}
              href={`/resources/analytics/${page.category.slug}/${page.slug}`}
            >
              {page.seo.title}
            </Anchor>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query SitemapQuery {
    # Resources Links
    allBlog(
      filter: { status: { eq: "published" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    allAuthors(
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    allWebinars(filter: { status: { eq: "published" } }) {
      edges {
        node {
          slug
          seo {
            title
          }
        }
      }
    }
    allArticleCategories(filter: { status: { eq: "published" } }) {
      edges {
        node {
          name
          id
          slug
        }
      }
    }
    allArticles(
      filter: { status: { eq: "published" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          header
          category {
            slug
          }
        }
      }
    }
    allDashboardExamplesCategories(
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    allDashboardExamples(
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          slug
          category {
            slug
          }
        }
      }
    }
    allKpiExamplesParentCategory {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    allKpiExamplesCategories {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    allKpiExamples(
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          slug
          name
          category {
            slug
          }
        }
      }
    }
    allMetricStackEpisode(
      filter: { status: { eq: "published" } }
      sort: { fields: episode_number, order: DESC }
    ) {
      edges {
        node {
          id
          status
          title
          guest_name
          episode_number
        }
      }
    }
    allAnalyticsCategory(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          seo {
            title
          }
        }
      }
    }
    allAnalytics(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          category {
            slug
          }
          seo {
            title
          }
        }
      }
    }

    # Product Links
    allAlternate(limit: 1000, filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          seo {
            title
          }
        }
      }
    }

    # Klips Links
    allGalleryKlips(
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          slug
          services {
            slug
          }
        }
      }
    }
    allGalleryServices(
      limit: 1000
      filter: { status: { eq: "published" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          slug
          just_docs
        }
      }
    }
    allKlipsSolutions(limit: 1000, filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }

    # Klipfolio Links
    allLegal(limit: 1000, filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    allWarehouses(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          seo {
            title
          }
        }
      }
    }
    allPowermetricsLp(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          seo {
            title
          }
        }
      }
    }
    allPartners(limit: 1000, filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`

export default Sitemap
